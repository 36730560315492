<template>
  <div class="FormWrap" v-loading="loading">
    <mia-navback @back="back" :isShowSave="!watchFlag" @submit="submitForm" />
    <div class="content">
     
          <el-form
            ref="basicForm"
            :model="form"
            :rules="FormRules"
            label-width="140px"
          >
            <!-- <el-row>
              <el-col :span="11">
                <el-form-item label="处方编号" prop="code">
                  <el-input
                    v-model="form.code"
                    :disabled="watchFlag"
                    placeholder="请填写处方编号"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row>
              <el-col :span="11">
                <el-form-item label="处方名称" prop="name">
                  <el-input
                    v-model="form.name"
                    :disabled="watchFlag"
                    placeholder="请填写处方名称"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row>
              <el-col :span="11">
                <el-form-item label="处方说明" prop="name">
                  <el-input
                  type="textarea"
                    v-model="form.description"
                    :disabled="watchFlag"
                    placeholder="请填写处方说明"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
    
    </div>
  </div>
</template>
<script>
import {
 getCode,
 GetPrescribeDetail,
 EditPrescribe
} from "@/api/prescribe";
export default {
  data() {
   
    return {
     
      loading: false,
      id: "",
      watchFlag:false,
      
      form: {
        name: "",
        code: "",
        type:'0',
       description:""
      },
      
      FormRules: {
        name: [
          { required: true, message: "请填写处方名称", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "名称长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        // code: [
        //   { required: true, message: "请填写处方编号", trigger: "blur" },
        //   {
        //     min: 1,
        //     max: 16,
        //     message: "名称长度在 1 到 16 个字符",
        //     trigger: "blur",
        //   },
        // ],
        description: [
          {
            min: 1,
            max: 255,
            message: "名称长度在 1 到 255 个字符",
            trigger: "blur",
          },
        ]
      },
      total: 0,
    };
  },
 
  mounted() {
    // this.$refs["basicForm"].resetFields();
  },
  methods: {
    getData() {
      GetPrescribeDetail(this.id).then((res) => {
        if (res.code === 1) {
          this.form = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    init(id, watch) {
      this.id = id;
      this.getData();
      if (watch == "watch") {
        this.watchFlag = true;
      }
    },
    back() {
      this.$emit("back");
    },
    // 保存
    submitForm() {
      this.$refs["basicForm"].validate((valid) => {
        if (valid) {
             
              EditPrescribe(this.form).then((res) => {
                const { code, msg } = res;
                if (code === 1) {
                  this.$message.success(msg);
                  this.$emit("back");
                  this.$emit("refresh");
                } else {
                  this.$message.error(msg);
                }
              });
        } else {
          return false;
        }
      });
    },
  },
};
</script>